import { Component, OnInit, Input } from '@angular/core';
import { DoughnutWidgetDatum, Color } from 'telos-design-system';
import { CurrencyPipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { AccountInfo } from '../account.service';

const pipe = new CurrencyPipe('en-US');

@Component({
  selector: 'app-account-details',
  template: `
    <h6 class="balance-title">
      401k Information
      <tds-toggle class="toggle" [control]="show"></tds-toggle>
      <span class="toggle-label">Show</span>
    </h6>
    <div class="account-wrapper" [class.hide-data]="!show.value">
      <h3 class="balance-total">
        <span class="sensitive">{{ accountInfo?.accountBalance | currency }}</span>
      </h3>
      <div class="balance-vested" *ngIf="accountInfo?.vestedBalance || accountInfo?.vestedBalance === 0">
        <span class="sensitive">{{ accountInfo?.vestedBalance | currency }}</span> vested<i
          class="balance-vested__icon uil uil-check"
        ></i>
      </div>
      <tds-doughnut-widget
        class="balance-breakdown"
        [data]="balanceBreakdown"
        [colors]="doughnutColors"
      ></tds-doughnut-widget>
      <div class="attribute-wrapper loan" *ngIf="accountInfo?.loan">
        <div class="icon-wrapper">
          <i class="uil uil-bill"></i>
        </div>
        <div class="key-value-pair">
          <label class="key">Loan Balance</label>
          <div class="value">
            <span class="sensitive">{{ accountInfo?.loan | currency }}</span>
          </div>
        </div>
      </div>
<!--      <div class="attribute-wrapper return" *ngIf="accountInfo?.rateReturn">-->
<!--        <div class="icon-wrapper">-->
<!--          <i class="uil uil-percentage"></i>-->
<!--        </div>-->
<!--        <div class="key-value-pair">-->
<!--          <label class="key">Rate of Return (1 Month)</label>-->
<!--          <div class="value">-->
<!--            <span class="sensitive">{{ accountInfo?.rateReturn | number }}%</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <section class="bottom-section">
      <a class="portal-link" [href]="portalUrl" target="_blank">
        Visit your 401k portal
        <i class="uil uil-angle-right"></i>
      </a>
      <img class="logo" src="assets/slavic401k-logo-mono.svg" />
    </section>
  `,
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit {
  @Input() accountInfo: AccountInfo;
  @Input() portalUrl: string;
  balanceBreakdown: DoughnutWidgetDatum[];
  doughnutColors = [Color.Primary, Color.Success, Color.Warning];
  show = new FormControl(false);

  ngOnInit() {
    const { deferral, match, rollOver, nonElective } = this.accountInfo || {};
    const balance = [
      { key: 'Deferral', value: deferral || 0 },
      { key: 'Match', value: match || 0 }
    ];
    if (nonElective) {
      balance.push({  key: 'Non Elective', value: nonElective });
    }
    if (rollOver) {
      balance.push({ key: 'Rollover', value: rollOver });
    }
    this.balanceBreakdown = balance.map(b => ({
      ...b,
      displayValue: pipe.transform(b.value)
    }));
  }
}
