import { Component, OnInit } from '@angular/core';
import { AccountService, AccountInfo, PlanSpecs } from './account.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isEnrolled = false;
  hasError = false;
  loading: boolean;
  accountInfo: AccountInfo;
  matchInfo: PlanSpecs;
  url;

  constructor(private service: AccountService) {}

  ngOnInit() {
    this.loading = true;
    const peoId = getQueryParam('peo_id');
    const key = getQueryParam('key');
    this.service.getAccountData(peoId, key).subscribe(
      ({ enroll, accountBalanceInfo, url, planSpecifications }) => {
        this.loading = false;
        this.isEnrolled = !enroll;
        this.url = url;
        if (planSpecifications) {
          this.matchInfo = planSpecifications;
        }
        if (!enroll) {
          this.accountInfo = accountBalanceInfo;
        }
      },
      () => {
        this.loading = false;
        this.hasError = true;
      }
    );
  }
}

function getQueryParam(name) {
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(location.search);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return results[2];
}
