import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(private http: HttpClient) {}

  getAccountData(peoId, key) {
    return this.http.get<WidgetData>(
      `${environment.apiBaseUrl}?peo_id=${peoId}&key=${key}`
    );
  }
}

export interface WidgetData {
  accountBalanceInfo: AccountInfo;
  enroll: boolean;
  url: string;
  planSpecifications: PlanSpecs;
}

export interface AccountInfo {
  accountBalance: number;
  deferral: number;
  loan: number;
  match: number;
  rollOver: number;
  rateReturn: number;
  vestedBalance: number;
  nonElective: number;
}

export interface PlanSpecs {
  Tier1_Disc_Match_Pct: string;
  Tier1_Disc_Max_Def_Pct: string;
  Tier2_Disc_Match_Pct: string;
  Tier2_Disc_Max_Defer_Pct: string;
}
