import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { TdsDoughnutWidgetModule, TdsToggleModule } from 'telos-design-system';

import { AppComponent } from './app.component';
import { EnrollMsgComponent } from './enroll-msg/enroll-msg.component';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { ErrorComponent } from './error/error.component';
import { LoadingDotsComponent } from './loading/loading-dots.component';

@NgModule({
  declarations: [
    AppComponent,
    EnrollMsgComponent,
    AccountDetailsComponent,
    ErrorComponent,
    LoadingDotsComponent
  ],
  imports: [
    BrowserModule,
    TdsDoughnutWidgetModule,
    TdsToggleModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
