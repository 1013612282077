import { Component, OnInit, Input } from '@angular/core';
import { PlanSpecs } from '../account.service';

@Component({
  selector: 'app-enroll-msg',
  template: `
    <p class="message">You haven’t enrolled in your employer’s 401k plan.</p>
    <div class="match" *ngIf="matchInfo && !isMatchInfoBlank">
      <i class="match__icon uil uil-dollar-alt"></i>
      <div class="match__content">
        <h6 class="match__content-title">It’s free money!</h6>
        <p>
          Your employer offers a match of up to {{ maxMatch }}% of your pay when
          you contribute {{ rateToMaxMatch }}%!
        </p>
      </div>
    </div>
    <div>
      <a [href]="enrollUrl" target="_blank" class="enroll-btn">
        Enroll now
        <i class="uil uil-angle-right"></i>
      </a>
    </div>
    <div class="disclaimer" *ngIf="matchInfo && !isMatchInfoBlank">
      Employer’s match might be subject to certain requirements.
    </div>
    <div class="disclaimer">Please refer to your 401(k) plan documents for eligibility requirements and entry schedule.</div>
  `,
  styleUrls: ['./enroll-msg.component.scss'],
})
export class EnrollMsgComponent implements OnInit {
  @Input() matchInfo: PlanSpecs;
  @Input() enrollUrl: string;
  maxMatch: number;
  rateToMaxMatch: number;

  ngOnInit() {
    if (!this.matchInfo || this.isMatchInfoBlank) {
      return;
    }
    const t1 = {
      match: +this.matchInfo.Tier1_Disc_Match_Pct.replace('%', ''),
      def: +this.matchInfo.Tier1_Disc_Max_Def_Pct.replace('%', ''),
    };
    const t2 = {
      match: +this.matchInfo.Tier2_Disc_Match_Pct.replace('%', ''),
      def: +this.matchInfo.Tier2_Disc_Max_Defer_Pct.replace('%', ''),
    };
    this.maxMatch = (t1.match * t1.def + t2.match * t2.def) / 100;
    this.rateToMaxMatch = t1.def + t2.def;
  }

  get isMatchInfoBlank(): boolean {
    return !(notNullAndNotBlank(this.matchInfo.Tier1_Disc_Match_Pct)
      && notNullAndNotBlank(this.matchInfo.Tier1_Disc_Max_Def_Pct)
      && notNullAndNotBlank(this.matchInfo.Tier2_Disc_Match_Pct)
      && notNullAndNotBlank(this.matchInfo.Tier2_Disc_Max_Defer_Pct));
  }
}

const notNullAndNotBlank = (val: string): boolean => {
  return val && (typeof val === 'string') && !!val.trim();
};
